import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React from "react";
import { hashString } from "react-hash-string";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import { db } from "../../../index";
import { useDoc } from "../../../hooks/useDoc";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";
import { BrainChaseWrapper } from "../BrainChaseWrapper";

const makeHash = (playerName: string) => {
  let ret = "";
  ret += String(Math.abs(hashString(playerName)));
  while (ret.length < 10) ret += "0";
  let EmanReyalp = playerName;
  EmanReyalp = EmanReyalp.split("").reverse().join("");
  ret += String(Math.abs(hashString(EmanReyalp)));
  while (ret.length < 20) ret += "0";
  return ret;
};

export const BrainChaseRoomInWithId: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const [playerName, setPlayerName] = React.useState<string>("");
  const [modalMessage, setModalMessage] = React.useState<string>("");
  const navigate = useNavigate();
  const [value, setValue] = React.useState("0");

  const [room] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const intoRoom = () => {
    if (playerName === "") return;
    if (!roomId) return;
    const hash = makeHash(playerName);
    (async () => {
      const roomDocSnap = await getDoc(doc(db, "brainchase", roomId));
      if (!roomDocSnap.exists()) {
        setModalMessage("存在しないルームIDです");
        return;
      }
      const hashDocSnap = await getDoc(
        doc(db, "brainchase", roomId, "user", hash)
      );
      if (hashDocSnap.exists()) {
        if (hashDocSnap.data()?.name === playerName) {
          navigate("/brainchase/" + roomId + "/" + hash + "/game");
          return;
        } else {
          setModalMessage("その名前は使用できません");
          return;
        }
      } else {
        setDoc(doc(db, "brainchase", roomId, "user", hash), {
          name: playerName,
          class: value,
        });
        navigate("/brainchase/" + roomId + "/" + hash + "/game");
      }
    })();
  };

  let subtitle: HTMLHeadingElement | null;

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalMessage("");
  }

  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography variant="h3" sx={{ margin: "auto", my: 2 }}>
        入室
      </Typography>
      <br />
      <text>ID：{roomId}</text>
      <br />
      <TextField
        label="名前(12文字以内)"
        type="text"
        value={playerName}
        onKeyPress={(e: any) => {
          if (e.key == "Enter") {
            intoRoom();
          }
        }}
        onChange={(e: any) => {
          setPlayerName(e.target.value);
        }}
        sx={{ mx: "auto", my: 5 }}
        inputProps={{ maxLength: 12 }}
      />
      <br />
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          クラス選択
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
        >
          {roomSet?.classes.map((c, i) => (
            <FormControlLabel
              key={i}
              value={String(i)}
              control={<Radio />}
              label={c.class}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <br />
      <Button
        onClick={intoRoom}
        variant={"contained"}
        size={"large"}
        sx={{ mx: "auto", my: 5 }}
      >
        エントリー
      </Button>
      <Modal
        isOpen={modalMessage !== ""}
        style={customStyles}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </Container>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
