import {
  Timestamp,
  Unsubscribe,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import React from "react";
import { db } from "..";

export const getServerTime = async (
  id: string,
  setServerTime: React.Dispatch<React.SetStateAction<Date | undefined | null>>
) => {
  // ダミーのコレクションにサーバー時刻を書き込む
  const ref = doc(db, "dummy_for_time_get", id ?? "player"); // 任意のダミーコレクション名
  await setDoc(ref, {
    timestamp: serverTimestamp(),
  });

  try {
    // 書き込みが終わったらデータを取得して返す
    const unSubscribe = onSnapshot(ref, (snapshot) => {
      const timestamp = snapshot.data({
        serverTimestamps: "estimate",
      })?.timestamp as Timestamp | undefined;
      console.log("getServerTime", timestamp?.toDate());
      setServerTime(timestamp?.toDate());
    });
    return unSubscribe;
  } catch (err) {
    console.error(err);
    return () => {
      console.log("unsubscribed");
    };
  }
};
