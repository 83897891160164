import { useEffect, useMemo, useState } from "react";
import { getServerTime } from "../lib/getServerTime";

export const useServerTime = ({
  serverTimeDocId,
  runTimer,
  timerInterval,
}: {
  serverTimeDocId: string;
  runTimer: boolean;
  timerInterval?: number;
}) => {
  const [serverTime, setServerTime] = useState<Date>();
  const [tempServetTime, setTempServetTime] = useState<Date | null>();
  useEffect(() => {
    let unsubscribed = () => {
      return;
    };
    getServerTime(serverTimeDocId, setTempServetTime).then((unsub) => {
      unsubscribed = unsub;
    });
    return () => unsubscribed();
  }, [serverTimeDocId]);
  const serverTimeDiff = useMemo(() => {
    return tempServetTime
      ? tempServetTime?.getTime() - new Date().getTime()
      : 0;
  }, [tempServetTime]);
  useEffect(() => {
    console.log(
      "useServerTime",
      serverTimeDocId,
      runTimer,
      serverTimeDiff,
      tempServetTime
    );
    if (!runTimer) {
      return;
    }
    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, timerInterval ?? 100);
    return () => {
      clearInterval(interval);
    };
  }, [runTimer, serverTimeDiff]);
  return { serverTime, serverTimeDiff };
};

export const useServerTimeDiff = ({
  serverTimeDocId,
}: {
  serverTimeDocId: string;
}) => {
  const [tempServetTime, setTempServetTime] = useState<Date | null>();

  useEffect(() => {
    getServerTime(serverTimeDocId, setTempServetTime);
  }, [serverTimeDocId]);
  const serverTimeDiff = useMemo(() => {
    return tempServetTime
      ? tempServetTime?.getTime() - new Date().getTime()
      : 0;
  }, [tempServetTime]);
  console.log("useServerTime", serverTimeDiff, tempServetTime);
  return { serverTimeDiff };
};
