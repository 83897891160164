import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useCollection, WithId } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import { useServerTime } from "../../../hooks/useServetTime";
import { BrainChaseWrapper } from "../BrainChaseWrapper";
import { Bg } from "../common/Bg";
import { ScreenTimer } from "../component/ScreenTimer";
import { BrainChaseUser } from "../player/BrainChaseGame";
import { BrainChaseRoom, BrainChaseSet } from "./AdminBrainChaseRoomManager";

type RankUser = {
  name: string;
  currentProblem: number;
  clearTime: number | null;
  lastTime: number;
  class: string;
};

export const AdminBrainChaseScreen: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const { dataList: serverUsersData } = useCollection<BrainChaseUser>({
    path: roomId ? "brainchase/" + roomId + "/user" : null,
  });
  const [room] = useDoc<BrainChaseRoom>({
    path: roomId ? "brainchase/" + roomId : null,
  });
  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const start = room?.mode === "playing";

  const { serverTime } = useServerTime({
    serverTimeDocId: "screen",
    runTimer: start || !!room?.replayOffset,
  });

  //replay実行時に、その時点でのUserDataを再現する計算が高頻度で実行されるので、できるだけ高速化する
  const displayUsersData = useMemo(() => {
    const serverTimeNum = serverTime?.getTime();
    return room?.replayOffset && serverUsersData && serverTime
      ? serverUsersData.map((user) => {
          const filteredProblem: {
            [key: string]: {
              time: Timestamp;
            };
          } = {};

          let OK = -1;
          let NG = user.currentProblem;
          while (NG - OK > 1) {
            const mid = Math.floor((OK + NG) / 2);
            if (
              user.problem[mid].time &&
              (user.problem[mid].time as Timestamp).toDate().getTime() +
                (room.replayOffset ?? 0) <
                (serverTimeNum as number)
            ) {
              OK = mid;
            } else {
              NG = mid;
            }
          }

          if (OK === -1) {
            return {
              ...user,
              problem: filteredProblem,
              currentProblem: 0,
            };
          } else {
            for (let i = 0; i <= OK; i++) {
              filteredProblem[i.toString()] = user.problem[i] as {
                time: Timestamp;
              };
            }
          }
          return {
            ...user,
            problem: filteredProblem,
            currentProblem: Object.keys(filteredProblem).length,
          };
        })
      : serverUsersData;
  }, [room?.replayOffset, serverUsersData, serverTime]);

  const { userRanks, userClassList } = useRanksAndClassList({
    users: displayUsersData ?? serverUsersData,
    roomSet,
    room,
  });

  const stats = useMemo(() => {
    const stats: { [key: string]: number } = {};
    displayUsersData.forEach((r) => {
      if (r.currentProblem in stats) {
        stats[r.currentProblem ?? 0]++;
      } else {
        stats[r.currentProblem ?? 0] = 1;
      }
    });
    return stats;
  }, [displayUsersData]);

  //console.log(userRanks);

  return (
    <BrainChaseWrapper>
      <Bg>
        <Box sx={{ minHeight: "100vh", py: 4 }}>
          <Grid
            container
            sx={{
              userSelect: "none",
            }}
            spacing={1}
          >
            <Grid item xs={8} sx={{ maxHeight: "100vh", overflowY: "auto" }}>
              <Stack>
                {userRanks.map((r, i) => {
                  const rankColor = roomSet?.classes[parseInt(r.class)].color;
                  return (
                    <Card
                      key={r.name}
                      sx={{
                        my: 1,
                        py: 1,
                        mx: 4,
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          width: "100%",
                          px: 2,
                          fontSize: "1.5rem",
                        }}
                      >
                        <Grid
                          item
                          xs={1}
                          sx={{ display: "flex", alignItems: "end" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "4rem",
                              fontWeight: "bold",
                            }}
                            variant="h1"
                          >
                            {i + 1}
                          </Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              alignSelf: "center",
                              color: rankColor,
                            }}
                          >
                            {roomSet?.classes[parseInt(r.class)].class}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "4rem",
                              fontWeight: "bold",
                            }}
                            variant="h1"
                          >
                            {r.name}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          sx={{ display: "flex", alignItems: "end" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "3.8rem",
                              fontWeight: "bold",
                            }}
                            variant="h1"
                          >
                            {r.clearTime
                              ? `${Math.floor(r.clearTime / 60)}:${(
                                  "" +
                                  (Math.floor(r.clearTime) % 60)
                                ).padStart(2, "0")}:${
                                  "" + (Math.floor(r.clearTime * 10) % 10)
                                }`
                              : "Q." + (r.currentProblem + 1)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  );
                })}
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <ScreenTimer
                  room={room ?? null}
                  roomSet={roomSet ?? null}
                  serverTime={serverTime ?? null}
                  userClassList={userClassList}
                  replayOffset={room?.replayOffset}
                />
                <Box>
                  <Table
                    sx={{
                      fontSize: "3rem",
                      fontWeight: "bold",
                    }}
                  >
                    <TableHead>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                          }}
                          variant="h1"
                        >
                          Q
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                          }}
                          variant="h1"
                        >
                          人数
                        </Typography>
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {Object.entries(stats)
                        .sort((a, b) => -(parseInt(a[0]) - parseInt(b[0])))
                        .map(([q, n], i) => (
                          <TableRow key={i}>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                                variant="h1"
                              >
                                {q === "15" ? "FINISH" : `Q${parseInt(q) + 1}`}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontSize: "3rem",
                                  fontWeight: "bold",
                                }}
                                variant="h1"
                              >
                                {n}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Bg>
    </BrainChaseWrapper>
  );
};

const useRanksAndClassList = ({
  users,
  roomSet,
  room,
}: {
  users: WithId<BrainChaseUser>[];
  roomSet: BrainChaseSet | undefined;
  room: BrainChaseRoom | undefined;
}) => {
  const [userRanks, setUserRanks] = useState<RankUser[]>([]);
  const [userClassList, setUserClassList] = useState<string[]>([]);
  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  useEffect(() => {
    if (!users) {
      return;
    }
    if (users.length === 0 || !room?.startTime) {
      setUserRanks([]);
    } else {
      const startTimeSec =
        (room?.startTime as Timestamp).toDate().getTime() / 1000;
      const ranks = users
        .filter((r) => r.currentProblem && r.currentProblem > 0)
        .map((r) => {
          return {
            name: r.displayName ?? r.name,
            currentProblem: r.currentProblem ?? 0,
            clearTime:
              roomSet && r.problem[roomSet?.problems.length - 1]?.time
                ? (r.problem[roomSet?.problems.length - 1]?.time as Timestamp)
                    .toDate()
                    .getTime() /
                    1000 -
                  startTimeSec -
                  defaultOffsetTime
                : null,
            lastTime:
              r.currentProblem > 0
                ? (r.problem[r.currentProblem - 1]?.time as Timestamp)
                    .toDate()
                    .getTime() /
                    1000 -
                  startTimeSec
                : 0,
            class: r.class,
          } as RankUser;
        })
        .sort((a, b) => {
          if (a.clearTime && b.clearTime) {
            return a.clearTime - b.clearTime;
          }
          if (a.currentProblem === b.currentProblem) {
            return a.lastTime - b.lastTime;
          }
          return b.currentProblem - a.currentProblem;
        });
      setUserRanks(ranks);
    }

    const userClassList = Array.from(new Set(users.map((data) => data.class)));

    setUserClassList(userClassList);
  }, [users]);

  return { userRanks, userClassList };
};
