import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import { useNavigate, useParams } from "react-router-dom";

import { useAllUserHash } from "../../../hooks/serverData/useAllUserHash";
import { useServerTime } from "../../../hooks/useServetTime";
import { db } from "../../../index";
import { DisplayMode, RawProblem, V2Room } from "../../../type";
import { RecommendedButton } from "../../admin/component/RecommendedButton";
import { getPagePointFromRaw } from "../score/pagePoint";

const RoomManager: React.FC = (props) => {
  const [hasSetsId, setHasSetsId] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [roomId, setRoomId] = useState<string>("");
  const [probIds, setProbIds] = useState<string[]>([]);
  const [probSum, setProbSum] = useState<number>(0);
  const [problems, setProblems] = useState<RawProblem[]>([]);
  const [room, setRoom] = useState<V2Room>({
    currentProblemId: "1",
    setId: "",
    displayMode: "wait",
    lastUpdate: null,
    probInd: 0,
    page: 0,
    closeImage: false,
  });

  const updateSetId = useCallback((newSetId: string) => {
    setRoom({ ...room, setId: newSetId });
  }, []);

  const updateDisplayMode = (newDisplayMode: DisplayMode) => {
    setRoom({
      ...room,
      displayMode: newDisplayMode,
      page: 0,
      closeImage: false,
    });
  };

  const updateLastUpdate = (newLastUpdate?: Date) => {
    setRoom({
      ...room,
      lastUpdate: Timestamp.fromDate(newLastUpdate || new Date()),
    });
  };

  const updateProbInd = (newProbInd: number) => {
    setRoom({ ...room, probInd: newProbInd });
  };

  const urlParams = useParams<{ roomId: string }>();
  const navigate = useNavigate();
  const goTo = (url: string) => {
    navigate(url);
  };

  // ******************** Time ******************** //

  const [start, setStart] = useState(false);
  const onStart = useCallback(() => {
    setStart(true);
  }, [setStart]);

  const { serverTime } = useServerTime({
    serverTimeDocId: "admin",
    runTimer: start,
  });

  const users = useAllUserHash(roomId);

  React.useEffect(() => {
    onStart();
    if (urlParams.roomId) setRoomId(urlParams.roomId);
  }, []);

  React.useEffect(() => {
    (async () => {
      const tmpRoomId = urlParams.roomId ? urlParams.roomId : "";
      const roomRef = doc(db, "v2rooms", tmpRoomId);
      const docSnap = await getDoc(roomRef);
      if (docSnap.exists()) {
        const roomDoc = await getDoc(roomRef);
        if (roomDoc.data()?.setId !== "") {
          setHasSetsId(true);
        }
        setRoom(roomDoc.data() as V2Room);
        funcSetProbIds(roomDoc.data()?.setId, false);
      } else {
        setHasSetsId(false);
        updateLastUpdate(serverTime);
        console.log("tempRoomId", tmpRoomId);
        await setDoc(roomRef, {
          currentProblemId: "1",
          setId: "",
          displayMode: "wait",
          page: 0,
          lastUpdate: null,
          probInd: 0,
        });
      }
      setRoomId(tmpRoomId);
    })();
  }, [db, roomId]);

  const determineSetId = useCallback(() => {
    if (room.setId === "") return;
    (async () => {
      const eventDocSnap = await getDoc(doc(db, "sets", room.setId));
      if (!eventDocSnap.exists()) {
        setModalType("invalidSetId");
        return;
      }
      updateLastUpdate(serverTime);
      setHasSetsId(true);
      updateDisplayMode("wait");
      updateProbInd(0);
      funcSetProbIds(room.setId, true);
    })();
  }, [db, room, serverTime]);

  const funcSetProbIds = useCallback(
    (id: string, init: boolean) => {
      (async () => {
        if (id === "") return;
        console.log(room.setId);
        console.log(id);

        const setsDoc = await getDoc(doc(db, "sets", id));
        const problems = setsDoc.data()?.problems as RawProblem[];

        setProblems(problems);
        setProbIds(problems.map((e: any) => e.problemId));
        setProbSum(problems.length);
        if (init) {
          setDoc(doc(db, "v2rooms", roomId), {
            currentProblemId: problems[0].problemId,
            setId: id,
            displayMode: "wait",
            page: 0,
            lastUpdate: null,
            probInd: 0,
          } as V2Room);
        }
      })();
    },
    [room]
  );

  const changeDisplayMode = useCallback(
    (mode: string) => {
      if (mode === "prob") {
        console.log("roomId", roomId, room, room.currentProblemId, serverTime);
        setDoc(doc(db, "v2rooms", roomId, "submits", room.currentProblemId), {
          startTime: serverTime ?? serverTimestamp(),
        });
      }
      console.log("changeDisplayMode", mode, room);
      setDoc(
        doc(db, "v2rooms", roomId),
        {
          displayMode: mode,
          page: 0,
          closeImage: false,
          lastUpdate: serverTimestamp(),
        } as V2Room,
        {
          merge: true,
        }
      );
      updateDisplayMode(mode as DisplayMode);
    },
    [db, roomId, room, serverTime]
  );

  const changeCloseImage = useCallback(() => {
    setDoc(
      doc(db, "v2rooms", roomId),
      {
        closeImage: true,
        lastUpdate: serverTimestamp(),
      } as V2Room,
      {
        merge: true,
      }
    );
    setRoom((prev) => {
      return {
        ...prev,
        closeImage: true,
      };
    });
  }, [db, roomId, room, serverTime]);

  const changeAdvancePage = useCallback(() => {
    setDoc(
      doc(db, "v2rooms", roomId),
      {
        page: room.page + 1,
        lastUpdate: serverTimestamp(),
      } as V2Room,
      {
        merge: true,
      }
    );
    setRoom((prev) => {
      return {
        ...prev,
        page: room.page + 1,
      };
    });
  }, [db, roomId, room, serverTime]);

  const changeCurrentProblemId = useCallback(
    (diff: number) => {
      if (room.displayMode === "finish" && diff > 0) return;
      if (room.displayMode === "finish" && diff < 0) {
        updateDisplayMode("wait");
        console.log(room, room.currentProblemId);
        setDoc(
          doc(db, "v2rooms", roomId),
          {
            displayMode: "wait",
            page: 0,
          },
          {
            merge: true,
          }
        );
        return;
      }
      const nextInd = room.probInd + diff;
      console.log(
        "nextInd",
        nextInd,
        room.probInd,
        probSum,
        probIds,
        probIds[nextInd]
      );
      if (nextInd < 0) {
        return;
      }
      if (nextInd >= probSum) {
        updateDisplayMode("finish");
        setDoc(
          doc(db, "v2rooms", roomId),
          {
            displayMode: "finish",
            page: 0,
          },
          {
            merge: true,
          }
        );
        return;
      }

      setDoc(doc(db, "v2rooms", roomId), {
        currentProblemId: probIds[nextInd],
        setId: room.setId,
        displayMode: "wait",
        page: 0,
        probInd: nextInd,
      });
      setRoom((prev) => {
        return {
          ...prev,
          currentProblemId: probIds[nextInd],
          displayMode: "wait",
          page: 0,
          probInd: nextInd,
        };
      });
    },
    [room]
  );

  const calcScore = () => {
    (async () => {
      const scores = new Map<string, number>();
      const scoresList = await Promise.all(
        new Array(probIds.length).fill(0).map(async (_, i) => {
          const querySnapshot2 = await getDocs(
            collection(db, "v2rooms", roomId, "submits", probIds[i], "users")
          );
          let correctPlayers: {
            submitTime: number;
            point: number;
            id: string;
          }[] = [];
          querySnapshot2.forEach((doc) => {
            if (scores.get(doc.id) === undefined) {
              scores.set(doc.id, 0);
            }
            if (doc.data()?.correct) {
              correctPlayers = [
                ...correctPlayers,
                {
                  submitTime: doc.data()?.submitTime,
                  point: doc.data()?.point,
                  id: doc.id,
                },
              ];
            }
          });
          correctPlayers.sort((a, b) => -(a.submitTime - b.submitTime));
          const problem = problems.find(
            (prob) => prob.problemId === probIds[i]
          );

          const rankPoints = problem?.rankPoint
            .split(",")
            .map((e) => parseInt(e)) ?? [0];
          for (let j = 0; j < correctPlayers.length; j++) {
            const player = correctPlayers[j];
            const rankPoint = rankPoints?.[Math.min(j, rankPoints.length - 1)];
            const point =
              (scores.get(player.id) ?? 0) +
              rankPoint +
              (player.submitTime / 1000) *
                parseInt(
                  problem?.timeCoefficient === "なし"
                    ? "0"
                    : problem?.timeCoefficient || "0"
                ) +
              (getPagePointFromRaw(
                (parseFloat(problem?.time ?? "0") - player.submitTime) / 1000,
                problem?.problemImageSwitchingTime,
                problem?.pagePoint
              ) ?? 0);

            if (point) {
              scores.set(correctPlayers[j].id, point);
            }
          }
          return scores;
        })
      );
      console.log(scores, scoresList);
      scoresList
        .map((e) => [...e.entries()])
        .forEach((keyValues) => {
          for (let i = 0; i < keyValues.length; i++) {
            setDoc(doc(db, "v2rooms", roomId, "scores", keyValues[i][0]), {
              totalScore: keyValues[i][1],
            });
          }
        });
      setDoc(doc(db, "v2rooms", roomId), room);
      updateLastUpdate(serverTime);
    })();
  };

  let subtitle: HTMLHeadingElement | null;

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalType("");
  }
  // console.log(room);

  if (hasSetsId) {
    return (
      <Container>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={() => {
              navigate("/admin");
            }}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={() => {
              navigate("/admin/v2room");
            }}
          >
            Room
          </Link>
          <Typography key="3" color="text.primary">
            {urlParams.roomId}
          </Typography>
        </Breadcrumbs>
        <p>RoomManager</p>
        <br />
        <p>ルームID：{roomId}</p>
        <p>セットID：{room.setId}</p>
        <p>問題ID：{room.currentProblemId}</p>
        <p>画面の状態：{room.displayMode}</p>
        <p>
          第{room.probInd + 1}問 / 計{probSum}問
        </p>
        {room.displayMode === "wait"}
        <p>次の操作</p>
        <RecommendedButton
          displayMode={room.displayMode}
          changeDisplayMode={changeDisplayMode}
          changeCurrentProblemId={changeCurrentProblemId}
          changeCloseImage={changeCloseImage}
          changeAdvancePage={changeAdvancePage}
          page={room.page}
          problems={problems}
          currentProblemId={room.currentProblemId}
        />
        <p>進行に必要な操作</p>
        <Button
          onClick={() => changeDisplayMode("url")}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          URL・QRコード表示
        </Button>
        <Button
          onClick={() => changeDisplayMode("wait")}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          待機
        </Button>
        <Button
          onClick={() => calcScore()}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          ランキング更新
        </Button>
        <Button
          onClick={() => changeDisplayMode("ranking")}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          ランキング表示
        </Button>
        {room.displayMode === "explain" && (
          <Button
            onClick={() => changeDisplayMode("explain-submission")}
            sx={{
              m: 1,
            }}
          >
            提出解答表示
          </Button>
        )}
        {room.displayMode === "explain-submission" && (
          <Button
            onClick={() => changeDisplayMode("explain")}
            sx={{
              m: 1,
            }}
          >
            現在ランキング表示
          </Button>
        )}
        <p>やむを得ない場合の操作</p>
        <Button
          onClick={() => changeCurrentProblemId(-1)}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          ←前の問題へ
        </Button>
        <Button
          onClick={() => changeCurrentProblemId(+1)}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          次の問題へ→
        </Button>
        <br />
        <p>スクリーンを映す端末で以下を押す</p>
        <Button
          onClick={() => {
            goTo("/admin/v2room/" + urlParams.roomId + "/screen");
          }}
          variant="contained"
        >
          スクリーン表示
        </Button>
        <br />
        <ul>
          {users.map((user, i) => (
            <li key={i}>{user.name}</li>
          ))}
        </ul>
      </Container>
    );
  } else {
    return (
      <Container>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={() => {
              navigate("/admin");
            }}
          >
            Admin
          </Link>
          <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={() => {
              navigate("/admin/v2room");
            }}
          >
            Room
          </Link>
          <Typography key="3" color="text.primary">
            {urlParams.roomId}
          </Typography>
        </Breadcrumbs>
        <p>RoomManager</p>
        <br />
        <p>roomId：{urlParams.roomId}</p>
        <p>このルームで使うセットIDを入力してください</p>
        <p>※一度入力したセットIDの変更はできません</p>
        <br />
        <TextField
          type="text"
          label={"セットID"}
          value={room.setId}
          onChange={(e: any) => {
            updateSetId(e.target.value);
          }}
        />
        <Button onClick={determineSetId} variant="contained" sx={{ mx: 1 }}>
          開始
        </Button>
        <Modal
          isOpen={modalType === "invalidSetId"}
          style={customStyles}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
            {"存在しないセットIDです"}
          </h2>
          <button onClick={closeModal}>OK</button>
        </Modal>
      </Container>
    );
  }
};

export default RoomManager;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
