import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BrainChaseWrapper } from "../BrainChaseWrapper";

export const AdminBrainChaseRoomIn: React.FC = (props) => {
  const [roomId, setRoomId] = React.useState<string>("");
  const navigate = useNavigate();
  const intoRoom = () => {
    if (roomId === "") return;
    navigate(`/admin/brainchase/` + roomId);
  };

  return (
    <Container>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin
        </Link>
        <Typography key="3" color="text.primary">
          Room
        </Typography>
      </Breadcrumbs>

      <p>Admin BrainChase RoomIn</p>
      <br />
      <TextField
        type="text"
        label="ルームID"
        value={roomId}
        onChange={(e: any) => {
          setRoomId(e.target.value);
        }}
      />
      <br />
      <Button onClick={intoRoom} variant="contained">
        入室
      </Button>
    </Container>
  );
};
