import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ReactNode, FC } from "react";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#d32f2f",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#212121",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

type Props = {
  children: ReactNode;
};

export const BrainChaseWrapper: FC<Props> = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ color: "#ffffff" }}>{children}</div>
    </ThemeProvider>
  );
};
