import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FieldValue,
  Timestamp,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import { useServerTimeDiff } from "../../../hooks/useServetTime";
import { RawTowerProblem } from "../../v2arena/admin/AdminSetEdit";
import { BrainChaseUser } from "../player/BrainChaseGame";
import { AdminSorterSelector } from "./component/AdminSorterSelector";
import { AdminUserTableRow } from "./component/AdminUserTableRow";

export type BrainChaseRoom = {
  setId: string;
  startTime?: Timestamp | FieldValue;
  mode: string;
  timeLimit?: number;
  replayOffset?: number;
};

export type BrainChaseSet = {
  type: "brainchase";
  problems: RawTowerProblem[];
  classes: { class: string; time: string; color: string }[];
  config: {
    [key: string]: string;
  };
};

const modeNames: {
  [key: string]: string;
} = {
  waiting: "待機中",
  playing: "プレイ中",
  end: "終了",
};

export const AdminBrainChaseRoomManager: React.FC = () => {
  const navigate = useNavigate();
  const { roomId } = useParams<{ roomId: string }>();
  const [room, setRoom] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
    initialData: {
      mode: "waiting",
      setId: "default",
    },
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const { docList: users } = useCollection<BrainChaseUser>({
    path: roomId ? "brainchase/" + roomId + "/user" : null,
  });

  const [sorter, setSorter] = useState("name");

  const handleSorterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSorter((event.target as HTMLInputElement).value);
  };

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  const sorterTable: {
    [key: string]: (a: BrainChaseUser, b: BrainChaseUser) => number;
  } = {
    name: (a: BrainChaseUser, b: BrainChaseUser) => {
      return a.name.localeCompare(b.name);
    },
    rank: (a: BrainChaseUser, b: BrainChaseUser) => {
      return (a.rank ?? 0) - (b.rank ?? 0);
    },
  };

  const { serverTimeDiff } = useServerTimeDiff({
    serverTimeDocId: "screen",
  });

  const setReplayOffset = () => {
    if (room?.startTime) {
      const now = new Date().getTime() + serverTimeDiff;
      const start = (room.startTime as Timestamp).toDate().getTime();
      const offset = now - start;
      setRoom({
        ...room,
        replayOffset: offset,
      });
    }
  };

  if (!roomId) {
    navigate("/admin");
    return <></>;
  }

  return (
    <Container>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin
        </Link>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            navigate("/admin/brainchase");
          }}
        >
          Room
        </Link>
        <Typography key="3" color="text.primary">
          {roomId}
        </Typography>
      </Breadcrumbs>
      <h1>BrainChase RoomManager</h1>
      <h2>プレイ情報</h2>
      <p>ルームID: {roomId}</p>
      {room && (
        <>
          <p>モード: {modeNames[room.mode]}</p>
        </>
      )}
      <Box sx={{ my: 2 }} />
      {roomSet && (
        <>
          <h2>セット情報</h2>
          <p>種目名: {roomSet?.config?.title ?? "未設定"}</p>
          <p>問題数: {roomSet.problems.length}</p>
          <p>クラス数: {roomSet.classes.length}</p>
        </>
      )}
      <Box sx={{ my: 2 }} />
      <h2>コントローラー</h2>
      {room && room.mode === "waiting" && (
        <Button
          onClick={() => {
            const userClassList = Array.from(
              new Set(users.map((doc) => doc.data().class))
            );
            const longestWaitTime = userClassList.reduce((acc, cur) => {
              const time = roomSet?.classes[parseInt(cur)]?.time;
              if (!time) {
                return acc;
              }
              const t = parseInt(time);
              return t > acc ? t : acc;
            }, 0);
            setRoom({
              ...room,
              startTime: serverTimestamp(),
              mode: "playing",
              timeLimit:
                longestWaitTime + parseInt(roomSet?.config?.timeLimit ?? "600"),
            });
          }}
          variant="contained"
          sx={{ my: 1, mx: 1 }}
        >
          開始
        </Button>
      )}
      {room && (
        <Button
          onClick={() => {
            setRoom({
              ...room,
              mode: "end",
            });
            users
              .map((doc, i) => {
                const r = doc.data();
                return {
                  ref: doc.ref,
                  name: r.name,
                  currentProblem: r.currentProblem ?? 0,
                  clearTime:
                    roomSet &&
                    r.problem?.[roomSet?.problems.length - 1]?.time &&
                    (room?.startTime as Timestamp)
                      ? (
                          r.problem?.[roomSet?.problems.length - 1]
                            ?.time as Timestamp
                        )
                          .toDate()
                          .getTime() /
                          1000 -
                        (room?.startTime as Timestamp)?.toDate().getTime() /
                          1000 -
                        defaultOffsetTime
                      : null,
                  lastTime:
                    r.currentProblem > 0
                      ? (r.problem?.[r.currentProblem - 1]?.time as Timestamp)
                          .toDate()
                          .getTime() /
                          1000 -
                        (room?.startTime as Timestamp)?.toDate().getTime() /
                          1000
                      : 99999999,
                };
              })
              .sort((a, b) => {
                if (a.clearTime && b.clearTime) {
                  return a.clearTime - b.clearTime;
                }
                if (a.currentProblem === b.currentProblem) {
                  return a.lastTime - b.lastTime;
                }
                return b.currentProblem - a.currentProblem;
              })
              .forEach((r, i) => {
                const ref = r.ref;
                console.log(r, i + 1);
                setDoc(ref, { rank: i + 1 }, { merge: true });
              });
          }}
          variant="contained"
          sx={{ my: 1, mx: 1 }}
        >
          終了
        </Button>
      )}
      {room && room.mode === "end" && (
        <Button
          onClick={setReplayOffset}
          variant="contained"
          sx={{ my: 1, mx: 1 }}
        >
          リプレイ再生
        </Button>
      )}
      <h2>ユーザー情報</h2>
      <p>参加者数: {users?.length}人 </p>

      <p>
        {roomSet?.classes.map((c, i) => {
          const count = users?.filter(
            (doc) => doc.data().class === i.toString()
          ).length;
          return (
            <Fragment key={i}>
              <span style={{ color: c.color }}>■</span>
              {c.class}: {count}人 /{" "}
            </Fragment>
          );
        })}
      </p>

      <AdminSorterSelector sorter={sorter} onChange={handleSorterChange} />

      <Box sx={{ my: 2 }}>
        <Table
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            textAlign: "left",
            fontSize: "1.2rem",
            border: "1px solid #333",
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell
                sx={{
                  width: "10%",
                  minWidth: "120px",
                }}
              >
                編集
              </TableCell>
              <TableCell>クラス</TableCell>
              <TableCell>問題数</TableCell>
              <TableCell>クリア時間</TableCell>
              <TableCell>最終順位</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users
                .sort((a, b) => {
                  return sorterTable[sorter](
                    a.data() as BrainChaseUser,
                    b.data() as BrainChaseUser
                  );
                })
                .map((user, i) => {
                  return (
                    <AdminUserTableRow
                      key={i}
                      user={user.data() as BrainChaseUser}
                      userRef={user.ref}
                      roomSet={roomSet ?? null}
                      room={room ?? null}
                    />
                  );
                })}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <h2>問題別最速リスト</h2>
        <Table
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            textAlign: "left",
            fontSize: "1.2rem",
            border: "1px solid #333",
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: "60px",
                  borderRight: "1px solid black",
                  color: "black",
                }}
              >
                問題番号
              </TableCell>
              <TableCell>1位名前</TableCell>
              <TableCell
                style={{
                  width: "60px",
                  borderRight: "1px solid black",
                  color: "black",
                }}
              >
                タイム
              </TableCell>
              <TableCell>2位名前</TableCell>
              <TableCell
                style={{ width: "60px", borderRight: "1px solid black" }}
              >
                タイム
              </TableCell>
              <TableCell>3位名前</TableCell>
              <TableCell style={{ width: "60px" }}>タイム</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roomSet?.problems.map((_, i) => {
              const fastest = users
                .map((doc) => {
                  const r = doc.data();
                  if (!room?.startTime || !r.problem?.[i]?.time) {
                    return {
                      name: r.name,
                      class: r.class,
                      time: undefined,
                    };
                  }
                  return {
                    name: r.name,
                    time:
                      Math.round(
                        (!r.problem?.[i]?.time
                          ? 0
                          : i === 0
                          ? ((r.problem?.[i]?.time as Timestamp)?.toMillis() ??
                              0) /
                              1000 -
                            ((room.startTime as Timestamp)?.toMillis() ?? 0) /
                              1000 -
                            parseInt(roomSet.classes[parseInt(r.class)].time) -
                            defaultOffsetTime
                          : ((r.problem?.[i]?.time as Timestamp)?.toMillis() ??
                              0) /
                              1000 -
                            ((
                              r.problem?.[i - 1]?.time as Timestamp
                            )?.toMillis() ?? 0) /
                              1000) * 100
                      ) / 100,
                    class: r.class,
                  };
                })
                .filter((r) => r.time)
                .sort((a, b) => {
                  return (a.time as number) - (b.time as number);
                });
              return (
                <TableRow key={i}>
                  <TableCell
                    style={{
                      width: "60px",
                      textAlign: "right",
                      borderRight: "1px solid black",
                    }}
                  >
                    {i + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {fastest[0]?.name && (
                      <>
                        <span
                          style={{
                            color:
                              roomSet?.classes[parseInt(fastest[0]?.class)]
                                ?.color,
                          }}
                        >
                          ■{" "}
                        </span>
                        {fastest[0]?.name}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "45px",
                      borderRight: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {toSecondsText(fastest[0]?.time)}秒
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {fastest[1]?.name && (
                      <>
                        <span
                          style={{
                            color:
                              roomSet?.classes[parseInt(fastest[1]?.class)]
                                ?.color,
                          }}
                        >
                          ■{" "}
                        </span>
                        {fastest[1]?.name}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      width: "45px",
                      borderRight: "1px solid black",
                      textAlign: "right",
                    }}
                  >
                    {toSecondsText(fastest[1]?.time)}秒
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {fastest[2]?.name && (
                      <>
                        <span
                          style={{
                            color:
                              roomSet?.classes[parseInt(fastest[2]?.class)]
                                ?.color,
                          }}
                        >
                          ■{" "}
                        </span>
                        {fastest[2]?.name}
                      </>
                    )}
                  </TableCell>
                  <TableCell style={{ width: "45px", textAlign: "right" }}>
                    {toSecondsText(fastest[2]?.time)}秒
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};

const toSecondsText = (time: number | undefined) => {
  if (!time) {
    return "";
  }
  return (
    (((time * 100) / 100) | 0) +
    "." +
    ("0" + (((time * 100) | 0) % 100)).slice(-2)
  );
};
