import { Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { FC, useMemo } from "react";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";
type ScreenTimerProps = {
  room: BrainChaseRoom | null;
  roomSet: BrainChaseSet | null;
  serverTime: Date | null;
  userClassList: string[];
  replayOffset?: number;
};

export const ScreenTimer: FC<ScreenTimerProps> = (props) => {
  const {
    room,
    roomSet,
    serverTime,
    userClassList: rowUserClassList,
    replayOffset,
  } = props;
  const userClassList = useMemo(
    () =>
      roomSet
        ? rowUserClassList.toSorted(
            (a, b) =>
              parseInt(roomSet?.classes[parseInt(a)].time) -
              parseInt(roomSet?.classes[parseInt(b)].time)
          )
        : [],
    [rowUserClassList, roomSet]
  );
  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");

  const startTimeList = useMemo(
    () =>
      roomSet
        ? userClassList
            .map((playerClass) => {
              return {
                time: parseInt(roomSet?.classes[parseInt(playerClass)].time),
                class: playerClass,
              };
            })
            .toSorted((a, b) => a.time - b.time)
        : [],
    [roomSet, userClassList]
  );

  const elapsedTime = useMemo(
    () =>
      room?.startTime && serverTime
        ? serverTime.getTime() / 1000 -
          (room?.startTime as Timestamp)?.toDate().getTime() / 1000 -
          defaultOffsetTime -
          (replayOffset ?? 0) / 1000
        : 0,
    [serverTime]
  );

  const nextStartClass: string | undefined = useMemo(
    () =>
      room?.startTime && serverTime
        ? startTimeList.find((time) => time.time > elapsedTime)?.class
        : undefined,
    [serverTime, room, startTimeList]
  );

  const remainTime = useMemo(
    () =>
      room?.startTime && serverTime
        ? Math.ceil(
            parseInt(roomSet?.config.timeLimit ?? "0") +
              parseInt(
                roomSet?.classes[parseInt(userClassList.at(-1) ?? "0")]?.time ??
                  "0"
              ) -
              elapsedTime
          )
        : 0,
    [serverTime, room, roomSet, userClassList]
  );

  return (
    <>
      {remainTime <= 0 ? (
        <Typography
          sx={{
            fontSize: "8rem",
            fontWeight: "bold",
          }}
          variant="h1"
        >
          TIME UP
        </Typography>
      ) : elapsedTime > 0 ? (
        <>
          <Typography
            sx={{
              fontSize: "8rem",
              fontWeight: "bold",
            }}
            variant="h1"
          >
            <span style={{ fontSize: "4rem" }}>TIME: </span>
            {timeStr(elapsedTime)}
          </Typography>
          {roomSet &&
            (nextStartClass ? (
              <>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                  variant="h1"
                >
                  <span
                    style={{
                      color: roomSet?.classes[parseInt(nextStartClass)].color,
                    }}
                  >
                    {roomSet?.classes[parseInt(nextStartClass)].class}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                  variant="h1"
                >
                  スタートまで{" "}
                  {timeStr(
                    parseInt(roomSet?.classes[parseInt(nextStartClass)].time) -
                      elapsedTime
                  )}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                  variant="h1"
                >
                  終了まで{" "}
                  {timeStr(
                    parseInt(
                      roomSet.classes[parseInt(userClassList?.at(-1) ?? "0")]
                        .time
                    ) +
                      parseInt(roomSet.config.timeLimit) -
                      elapsedTime
                  )}
                </Typography>
              </>
            ))}
        </>
      ) : (
        <Typography
          sx={{
            fontSize: "8rem",
            fontWeight: "bold",
          }}
          variant="h1"
        >
          READY
        </Typography>
      )}
    </>
  );
};

const timeStr = (time: number) => {
  return `${("" + Math.floor(time / 60)).padStart(2, "0")}:${(
    "" +
    (Math.floor(time) % 60)
  ).padStart(2, "0")}:${"" + Math.floor((time * 10) % 10)}`;
};
